import { useEffect, useState } from "react";
import { getServicios } from "../Control/Firestore";
import React, { Component } from "react";
import NavBar from "../NavBar.css";
import { Link } from "react-router-dom";
import "../estilos/servicios.css";
import "../estilos/greek.css";
import personalización from "../icons/Personalizacion.gif";
import Tecnologia from "../icons/Tecnologia.gif";
import Analisis from "../icons/Analisis.gif";
import Negocios from "../icons/negocios.png";
import Construccion from "../icons/construccion.png";
import Educacion from "../icons/educacion.png";
import Ingenieria from "../icons/ingenieria.png";
import Servicio from "../icons/servicios.png";
import Inmobiliaria from "../icons/inmobiliaria.png";
import Comercio from "../icons/comercio.png";
import Finanzas from "../icons/finanzas.png";
import Gobierno from "../icons/gobierno.png";
import Medicina from "../icons/medicina.png";
import Legal from "../icons/legal.png";
import Manufactura from "../icons/manufactura.png";
import Ciencia from "../icons/ciencia.png";
import Logistica from "../icons/logistica.png";
import Automotriz from "../icons/automotriz.png";
import Entretenimiento from "../icons/entretenimiento.png";
import complemento from "../icons/complementos.png";
import Footer from "./Footer";
import Greek from "../icons/GreekAll.gif";
import { Helmet } from "react-helmet";

const Servicios = () => {
  const [servicios, setServicios] = useState([]);
  const [selectedServicio, setSelectedServicio] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const servicios = await getServicios();
        const serviciosData = servicios.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setServicios(serviciosData);
      } catch (error) {
        console.error("Error fetching equipo:", error);
      }
    };

    fetchData();
  }, []);

  const handleRowClick = (servicioId) => {
    setSelectedServicio(servicioId);
  };

  const descripcionServicio =
  "Desarrolla de software a medida. Con nuestro equipo de desarrollo tendrás el software a la medida de tu empresa y presupuesto. Consigue la gestión de TI de tu empresa con nuestro equipo para que cuentes con soporte personalizado a tu presupuesto";


  return (
    <div>
      <Helmet>
        <title>
          Desarrollo de software a medida | Grupo Greek
        </title>
        <meta name="description" content={descripcionServicio} />
      </Helmet>
      <div class="e-card playing">

        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>

        <div className="TituloService">Desarrollo de software</div>
      </div>
      <div>
        <div class="container-desarrollo">
          <div class="desarrollo-item-principal">
            <h1>Pensamos en tu crecimiento</h1>
            <div className="TextDesarrollo">
              <h2>
                Nuestro enfoque en tu crecimiento va más allá de ofrecer
                soluciones genéricas. Nos dedicamos a comprender tus objetivos y
                desafíos únicos para diseñar estrategias a medida que maximicen
                tus resultados. Creemos firmemente que cada situación requiere
                un enfoque personalizado, por lo que nos esforzamos por ofrecer
                soluciones innovadoras y adaptadas que te impulsen hacia el
                éxito deseado. Ya sea que busques expandirte en nuevos mercados,
                mejorar la eficiencia operativa o simplemente alcanzar un nuevo
                nivel de excelencia, estamos aquí para ayudarte a superar tus
                metas y llevarte más allá de tus expectativas.
              </h2>
            </div>
          </div>
          <div class="desarrollo-facilitar">
            <h1>Buscamos facilitar</h1>
            <div className="TextDesarrollo">
              <h2>
                Facilitamos tus objetivos con soluciones a la medida de tus
                necesidades. Enfocados en simplificar, creamos estrategias que
                hacen que cada paso sea más fácil y eficiente. Descubre cómo
                nuestro enfoque facilitador impulsa tu camino hacia el éxito sin
                complicaciones.
              </h2>
            </div>
          </div>
          <div class="desarrollo-tecnologias">
            <h1>Implementar tecnologías emergentes</h1>
            <div className="TextDesarrollo">
              <h2>
                Transforma tu empresa adoptando tecnologías emergentes con
                nuestro servicio de implementación. Desde la inteligencia
                artificial hasta la blockchain, guiamos tu transición hacia el
                futuro. Descubre cómo la implementación de tecnologías avanzadas
                puede potenciar tu competitividad y abrir nuevas oportunidades
                para tu negocio.
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Titulos">¿Por qué nosotros?</div>

      <section className="ContainerNosotros">
        <div className="Nosotros">
          <div className="CardNosotros">
            <div className="overlay"></div>
            <div className="circle">
              <p className="title">Personalizar</p>
            </div>
            <p className="textNosotros">
              Diferenciamos nuestro servicio al ofrecer soluciones completamente
              adaptadas a tus necesidades. Mientras otros siguen plantillas,
              nosotros creamos experiencias únicas que reflejan tu visión y
              superan las expectativas. Descubre la verdadera personalización
              que destaca y te lleva más allá de lo convencional.
            </p>
            <img src={personalización} className="NosotrosImagen"></img>
          </div>
          <div className="CardNosotros">
            <div className="overlay"></div>
            <div className="circle">
              <p className="title">Tecnolog&iacute;a</p>
            </div>
            <p className="textNosotros">
              En un mercado saturado, destacamos al incorporar las últimas
              tecnologías con maestría. Desde la inteligencia artificial hasta
              la vanguardia del desarrollo, elegirnos significa acceso a
              soluciones tecnológicas avanzadas que impulsan tu éxito. Descubre
              la diferencia de liderar con tecnología de vanguardia.
            </p>
            <img src={Tecnologia} className="NosotrosImagen"></img>
          </div>
          <div className="CardNosotros">
            <div className="overlay"></div>
            <div className="circle">
              <p className="title">An&aacute;lisis</p>
            </div>
            <p className="textNosotros">
              Con la ciencia de datos redefine la tomas decisiones.
              Distinguiéndonos de la competencia, fusionamos la experiencia con
              la tecnología para extraer insights significativos. Elige nuestro
              servicio para un análisis profundo que guía tu camino hacia el
              éxito basado en datos, marcando la diferencia en tu toma de
              decisiones.
            </p>
            <img src={Analisis} className="NosotrosImagen"></img>
          </div>
        </div>
      </section>

      <div className="Titulos">Nuestra metodologia de trabajo</div>

      <section class="metodologia-bento-row">
        <div class="bento-item-metodologia">
          <h1>Con la metodología correcta</h1>
          <h2>
            Alcanza tus metas con la metodología perfecta. Nuestro enfoque único
            y probado te guiará hacia el éxito, brindándote las herramientas y
            estrategias necesarias para destacar en tu camino. Descubre el poder
            de la excelencia con nuestra metodología que marca la diferencia.
          </h2>
        </div>
        <div class="bento-item-diagnostico">
          <h1>Diagnóstico</h1>
          <h2>
            Obtén claridad y toma decisiones informadas con nuestro servicio de
            diagnóstico. Nuestra evaluación exhaustiva proporciona insights
            precisos, permitiéndote identificar áreas de mejora y tomar medidas
            efectivas. Descubre la ruta hacia el crecimiento con nuestro
            diagnóstico experto y personalizado.
          </h2>
        </div>
        <div class="bento-item-diseño">
          <h1>Diseño</h1>
          <h2>
            Eleva tu presencia con diseño innovador. Nuestro equipo creativo da
            vida a tu visión con soluciones visualmente impactantes. Desde
            logotipos hasta experiencias digitales, transformamos ideas en
            estilos que cautivan y destacan. Descubre el poder del diseño que
            deja una impresión duradera.
          </h2>
        </div>
        <div class="bento-item-imagen">
          <img src={Greek} className="Arquitecto"></img>
        </div>
        <div class="bento-item-desarrollo">
          <h1>Desarrollo</h1>
          <h2>
            Convierte tus ideas en realidad con nuestro servicio de desarrollo
            experto. Desde la concepción hasta la implementación, creamos
            soluciones personalizadas que impulsan tu visión. Descubre el
            potencial ilimitado de tu proyecto con nuestro equipo de desarrollo
            dedicado a la excelencia.
          </h2>
        </div>
        <div class="bento-item-capacitacion">
          <h1>Capacitación</h1>
          <h2>
            Potencia tu crecimiento con nuestra capacitación especializada.
            Ofrecemos programas que impulsan habilidades clave y maximizan el
            rendimiento. Descubre el camino hacia la excelencia con nuestra
            formación diseñada para transformar tu equipo y alcanzar nuevos
            niveles de éxito.
          </h2>
        </div>
        <div class="bento-item-lanzamiento">
          <h1>Lanzamiento</h1>
          <h2>
            Impulsa tu éxito desde el principio con nuestro servicio de
            lanzamiento estratégico. Desde la planificación hasta la ejecución,
            creamos experiencias memorables que destacan tu producto o servicio.
            Descubre el arte de un lanzamiento efectivo que cautiva a tu
            audiencia y deja una impresión duradera.
          </h2>
        </div>
      </section>

      <div className="Titulos">Nuestros servicios</div>

      <div className="bento-services-container">
        {servicios.map((doc, index) => (
          <div key={index} className="bento-services">
            <div key={doc.id} className="bento-services-service">
              <div>{doc.id}</div>
              <div>
                <img src={doc.data.Imagen} alt="Imagen" />
              </div>
            </div>
            <div className="bento-item-service">
              <h1 className="tituloServicios">{doc.data.Titulo}</h1>
              <h2 className="descripcion">{doc.data.Descripcion}</h2>
              <Link to={`/contacto?servicio=${doc.id}`}>
                <button class="learn-more">
                  <span class="circleServices" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Cotizar</span>
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>

      <div className="Titulos">
        ¿En qué sectores puedo solicitar software a medida?
      </div>
      <section class="sectores">
        <div className="sectoresContainer">
          <div class="sector">
            <img src={Negocios} className="SectorImagen"></img>
            <h1 className="TextSector">Negocios</h1>
          </div>
          <div class="sector">
            <img src={Construccion} className="SectorImagen"></img>
            <h1 className="TextSector">Construcci&oacute;n</h1>
          </div>
          <div class="sector">
            <img src={Educacion} className="SectorImagen"></img>
            <h1 className="TextSector">Educaci&oacute;n</h1>
          </div>
          <div class="sector">
            <img src={Ingenieria} className="SectorImagen"></img>
            <h1 className="TextSector">Ingenier&iacute;a</h1>
          </div>
          <div class="sector">
            <img src={Servicio} className="SectorImagen"></img>
            <h1 className="TextSector">Servicios</h1>
          </div>
          <div class="sector">
            <img src={Inmobiliaria} className="SectorImagen"></img>
            <h1 className="TextSector">Inmobiliarias</h1>
          </div>
          <div class="sector">
            <img src={Comercio} className="SectorImagen"></img>
            <h1 className="TextSector">Comercios</h1>
          </div>
          <div class="sector">
            <img src={Finanzas} className="SectorImagen"></img>
            <h1 className="TextSector">Finanzas</h1>
          </div>
          <div class="sector">
            <img src={Gobierno} className="SectorImagen"></img>
            <h1 className="TextSector">Gobierno</h1>
          </div>
          <div class="sector">
            <img src={Medicina} className="SectorImagen"></img>
            <h1 className="TextSector">Medicina</h1>
          </div>
          <div class="sector">
            <img src={Legal} className="SectorImagen"></img>
            <h1 className="TextSector">Legal</h1>
          </div>
          <div class="sector">
            <img src={Manufactura} className="SectorImagen"></img>
            <h1 className="TextSector">Manufactura</h1>
          </div>
          <div class="sector">
            <img src={Ciencia} className="SectorImagen"></img>
            <h1 className="TextSector">Ciencia</h1>
          </div>
          <div class="sector">
            <img src={Logistica} className="SectorImagen"></img>
            <h1 className="TextSector">log&iacute;stica</h1>
          </div>
          <div class="sector">
            <img src={Automotriz} className="SectorImagen"></img>
            <h1 className="TextSector">Automotriz</h1>
          </div>
          <div class="sector">
            <img src={Entretenimiento} className="SectorImagen"></img>
            <h1 className="TextSector">Entretenimiento</h1>
          </div>
        </div>
      </section>

      <div className="Titulos">Complementos</div>

      <section className="complemento-bento-row">
        <div className="complemento-item">
          <img src={complemento} className="complemento"></img>
        </div>
        <div className="complemento-mantenimiento">
          <div className="complemento-item-contorno">
            <h1 className="TituloComplemento">Mantenimiento</h1>
            <h2 className="DescripcionComplemento">
              Garantiza el rendimiento continuo con nuestro servicio de
              mantenimiento especializado. Nos encargamos de mantener tus
              sistemas y equipos en óptimas condiciones, minimizando tiempo de
              inactividad y maximizando la eficiencia. Descubre la tranquilidad
              que brinda nuestro servicio de mantenimiento, permitiéndote
              centrarte en lo que realmente importa: el crecimiento de tu
              negocio.
            </h2>
          </div>
        </div>
        <div className="complemento-tecnologia">
          <div className="complemento-item-contorno">
            <h1 className="TituloComplemento">
              Integración de tecnologías emergentes
            </h1>
            <h2 className="DescripcionComplemento">
              Lleva tu empresa al futuro con nuestra experiencia en integración
              de tecnologías emergentes. Desde la inteligencia artificial hasta
              la realidad virtual, creamos soluciones que impulsan la
              innovación. Descubre cómo la integración de tecnologías avanzadas
              puede transformar tu operación y posicionarte a la vanguardia de
              tu industria.
            </h2>
          </div>
        </div>
        <div className="complemento-remodelacion">
          <div className="complemento-item-contorno">
            <h1 className="TituloComplemento">Remodelación de sistemas</h1>
            <h2 className="DescripcionComplemento">
              Revitaliza tu operación con nuestro servicio de remodelación de
              sistemas. Desde la evaluación hasta la implementación, renovamos y
              optimizamos tus sistemas existentes para un rendimiento máximo.
              Descubre el poder de una operación renovada con nuestra
              experiencia en remodelación de sistemas, llevando tu negocio a
              nuevos niveles de eficiencia y efectividad.
            </h2>
          </div>
        </div>
        <div className="complemento-integracion">
          <div className="complemento-item-contorno">
            <h1 className="TituloComplemento">Capacitaci&oacute;n</h1>
            <h2 className="DescripcionComplemento">
              Empodera a tu equipo con nuestra capacitación especializada.
              Ofrecemos programas adaptados a tus necesidades, impulsando
              habilidades clave y fomentando el desarrollo profesional. Descubre
              cómo nuestra capacitación personalizada puede fortalecer a tu
              equipo, maximizando su rendimiento y contribuyendo al éxito
              duradero de tu empresa.
            </h2>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Servicios;
