import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import Productos from './component/Productos';
import Navigation from './component/Navigation';
import Servicios from './component/Servicios';
import Proyectos from './component/Proyectos';
import Greek from './component/Greek';
import Equipo from './component/Equipo';
import Contacto from './component/Contacto';
import Aviso from './component/aviso';
import Certificaciones from './component/certificaciones';
import Detalles from './component/Detalles';
import Pago from './component/pago';
import React from 'react';

function App() {

  return (
    <div className='App'>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path='/' element={<Greek />} />
          <Route path='/Servicios' element={<Servicios />} />
          <Route path='/Equipo' element={<Equipo/>}/>
          <Route path='/Contacto' element={<Contacto/>}/>
          <Route path='/Aviso' element={<Aviso/>}/>
          <Route path='/Detalles' element={<Detalles/>} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
