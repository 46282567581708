import { useEffect, useState } from "react";
import React, { Component } from "react";
import NavBar from "../NavBar.css";
import { Link } from "react-router-dom";
import "../estilos/equipo.css";
import "../estilos/greek.css";
import Filosofia from "../icons/yin-yang.gif";
import HTML from "../icons/html.png";
import CSS from "../icons/css-3.png";
import JS from "../icons/archivo-js.png";
import Atoms from "../icons/atomo.png";
import Python from "../icons/archivo.png";
import Node from "../icons/nodo-js.png";
import Flutter from "../icons/flutter.png";
import Footer from "./Footer";

const Equipo = () => {
  return (
    <div>
      <div class="e-card playing">

        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>

        <div className="TituloService">Nosotros</div>
      </div>

      <div>
        <div class="containerFilosofia">
          <div class="filosofia">
            <h1>Filosof&iacute;a</h1>
            <div className="TextFilosofia">
              <h2>
                En el núcleo de nuestra esencia reside una filosofía arraigada
                en la excelencia, la innovación y el compromiso inquebrantable
                con nuestros clientes. Nos esforzamos por trascender las
                expectativas, brindando soluciones que no solo resuelven
                desafíos actuales, sino que también anticipan y abrazan el
                futuro. Cada proyecto es una oportunidad para demostrar nuestro
                enfoque único, donde la calidad, la transparencia y la
                colaboración son los pilares fundamentales. Creemos en la fuerza
                transformadora de la tecnología y la inteligencia empresarial, y
                canalizamos esta creencia en cada interacción, guiando a
                nuestros clientes hacia el éxito sostenible en la era digital.
                Somos más que proveedores de servicios; somos arquitectos de
                visiones, colaborando con nuestros clientes para construir un
                mañana empresarial sólido y próspero. Nuestra filosofía impulsa
                cada paso, asegurando que la excelencia sea la norma en todo lo
                que hacemos.
              </h2>
              <img src={Filosofia}></img>
            </div>
          </div>
          <div class="vision">
            <h1>Visi&oacute;n</h1>
            <div className="TextFilosofia">
              <h2>
                Nuestra visión es trascender las fronteras convencionales de la
                innovación, posicionándonos como arquitectos del cambio en el
                paisaje empresarial. Buscamos ser líderes en la entrega de
                soluciones tecnológicas que no solo aborden las necesidades
                actuales, sino que también definan el futuro de la
                transformación empresarial. En nuestra visión, cada proyecto es
                una oportunidad para marcar la diferencia, proporcionando
                soluciones que no solo son efectivas, sino que también inspiran
                un cambio positivo. Nos esforzamos por ser el catalizador que
                impulsa a las empresas hacia nuevos horizontes de éxito
                sostenible, guiándolas con una visión clara y un compromiso
                inquebrantable con la excelencia y la innovación. En esencia,
                buscamos crear un impacto duradero en el tejido mismo de la
                evolución empresarial, contribuyendo al crecimiento y la
                prosperidad de nuestros clientes en la era digital.
              </h2>
            </div>
          </div>
          <div class="mision">
            <h1>Misi&oacute;n</h1>
            <div className="TextFilosofia">
              <h2>
                Nuestra misión es impulsar la excelencia empresarial a través de
                soluciones tecnológicas innovadoras y adaptadas a las
                necesidades únicas de nuestros clientes. Nos comprometemos a ser
                arquitectos de la transformación, ofreciendo no solo servicios,
                sino colaboraciones estratégicas que trascienden las
                expectativas. Buscamos liderar la vanguardia tecnológica,
                proporcionando a nuestros clientes las herramientas y la
                inteligencia empresarial necesarias para prosperar en un entorno
                digital en constante evolución. En cada proyecto, nos esforzamos
                por superar los límites convencionales, creando un impacto
                positivo y duradero en las operaciones y el éxito general de
                nuestros clientes. A través de un enfoque transparente,
                colaborativo y centrado en el cliente, nuestra misión es ser el
                socio estratégico preferido, guiando a las empresas hacia el
                futuro con confianza y éxito sostenible.
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="platforms">
        <h1 className="Titulos">Tecnologías que utilizamos</h1>
        <section class="Plataformas">
          <div class="plataformasContainer">
            <div class="tec">
              <img src={HTML} className="SectorImagen"></img>
              <p className="TextSector">HTML</p>
            </div>
            <div class="tec">
              <img src={CSS} className="SectorImagen"></img>
              <p className="TextSector">CSS</p>
            </div>
            <div class="tec">
              <img src={JS} className="SectorImagen"></img>
              <p className="TextSector">Javascript</p>
            </div>
            <div class="tec">
              <img src={Atoms} className="SectorImagen"></img>
              <p className="TextSector">React</p>
            </div>
            <div class="tec">
              <img src={Python} className="SectorImagen"></img>
              <p className="TextSector">Python</p>
            </div>
            <div class="tec">
              <img src={Node} className="SectorImagen"></img>
              <p className="TextSector">Node</p>
            </div>
            <div class="tec">
              <img src={Flutter} className="SectorImagen"></img>
              <p className="TextSector">Flutter</p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Equipo;
