import React, { useState, useEffect } from "react";
import "../estilos/contacto.css";
import Footer from "./Footer";
import { Servicios, setDataContact } from "../Control/Firestore";
import { useLocation } from "react-router-dom";

const Contacto = () => {
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [solucion, setSolucion] = useState("");
  const [correo, setCorreo] = useState("");
  const [etapa, setEtapa] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [servicios, setServicios] = useState([]);
  const location = useLocation();
  const [servicioSeleccionado, setServicioSeleccionado] = useState("");
  const [envioExitoso, setEnvioExitoso] = useState(null);

  const contactData = {
    nombre,
    telefono,
    empresa,
    servicioSeleccionado,
    correo,
    etapa,
    descripcion,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const exitoso = await setDataContact(contactData);
    setEnvioExitoso(exitoso);
    setNombre("");
    setTelefono("");
    setEmpresa("");
    setSolucion("");
    setCorreo("");
    setEtapa("");
    setDescripcion("");
  };

  useEffect(() => {
    const fetchDataServices = async () => {
      try {
        const serviciosData = await Servicios();
        console.log("Servicios cargados:", serviciosData);
        setServicios(serviciosData);
      } catch (error) {
        console.error("Error fetching servicios:", error);
      }
    };

    fetchDataServices();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const servicio = searchParams.get("servicio");
    if (servicio) {
      setServicioSeleccionado(servicio);
    }
  }, [location.search]);

  useEffect(() => {
    let timeout;
    if (envioExitoso) {
      timeout = setTimeout(() => {
        setEnvioExitoso(null);
        // Recargar la página después de 5 segundos
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }, 5000); // Mostrar el mensaje durante 5 segundos
    }
    return () => clearTimeout(timeout);
  }, [envioExitoso]);

  return (
    <div>
      <div class="e-card playing">

        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>

        <div className="TituloService">Contáctanos</div>
      </div>

      <form class="form" onSubmit={handleSubmit}>
        <div id="login-lable">Ingresa tu información</div>
        <input
          class="form-content nombre"
          type="text"
          placeholder="Nombre completo"
          maxlength="40"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          required
        />
        <input
          class="form-content telefono"
          type="number"
          placeholder="Teléfono"
          maxlength="10"
          value={telefono}
          onChange={(e) => setTelefono(e.target.value)}
          required
        />
        <input
          class="form-content nombreEmpresa"
          type="text"
          placeholder="Nombre de la empresa"
          maxlength="20"
          value={empresa}
          onChange={(e) => setEmpresa(e.target.value)}
          required
        />
        <select
          className="form-content solucion"
          value={servicioSeleccionado}
          onChange={(e) => setServicioSeleccionado(e.target.value)}
          required
        >
          <option value="" disabled hidden>
            Servicio
          </option>
          {Array.isArray(servicios) &&
            servicios.map((servicio) => (
              <option key={servicio.id} value={servicio.id}>
                {servicio.id}
              </option>
            ))}
        </select>

        <input
          class="form-content correo"
          type="email"
          placeholder="Correo"
          maxlength="60"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
          required
        />
        <select
          class="form-content etapa"
          type="text"
          value={etapa}
          onChange={(e) => setEtapa(e.target.value)}
          required
        >
          <option value="" disabled hidden>
            Etapa
          </option>
          <option value="Inicial">Inicial</option>
          <option value="Desarrollo">En desarrollo</option>
          <option value="Avanzada">Avanzada</option>
          <option value="Terminada">Terminada</option>
        </select>
        <textarea
          class="form-content describe"
          placeholder="Describe brevemente tu proyecto"
          pattern="[^a-zA-Z0-9]"
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          required
        />
        <button className="envio">Continue</button>
      </form>
      <div>
        {envioExitoso === true && (
          <div className="mensaje mensaje-exito">El envío fue exitoso.</div>
        )}
        {envioExitoso === false && (
          <div className="mensaje mensaje-error">
            Hubo un error en el envío.
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Contacto;
