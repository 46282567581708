import React, { Component, useEffect, useState } from "react";
import NavBar from "../NavBar.css";
import Historial from "../Historial.css";
import {
  collection,
  doc,
  query,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import firebaseConfig from "../Connect/firebase-config";
import { async } from "@firebase/util";
import { Link } from "react-router-dom";

const Navigation = () => {
  useEffect(() => {
    const toggle = document.querySelector(".toggle");
    const items = document.querySelector(".items");

    const handleLinkClick = () => {
      toggle.classList.remove("rotate");
      items.classList.remove("active");
    };

    document.querySelectorAll(".a").forEach((link) => {
      link.addEventListener("click", handleLinkClick);
    });

    return () => {
      document.querySelectorAll(".a").forEach((link) => {
        link.removeEventListener("click", handleLinkClick);
      });
    };
  }, []);

  const handleToggleClick = () => {
    const toggle = document.querySelector(".toggle");
    const items = document.querySelector(".items");
    toggle.classList.toggle("rotate");
    items.classList.toggle("active");
  };

  return (
    <div>
      <nav className="menu">
        <div className="container">
          <Link className="logo" to="/">
            GREEK
          </Link>
          <ul className="items">
            <li>
              <Link className="a" to="/Servicios">
                Servicios
              </Link>
            </li>
            <li>
              <Link className="a" to="/Equipo">
                Equipo
              </Link>
            </li>
            <li>
              <Link className="a" to="/Contacto">
                Contacto
              </Link>
            </li>
          </ul>
          <div className="toggle" onClick={handleToggleClick}>
            |||
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
