import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  onSnapshot,
  getDocs,
  addDoc,
} from "firebase/firestore";
import config from "../Connect/firebase-config";

const app = initializeApp(config);
const db = getFirestore(app);

export const getEquipo = async () => {
  try {
    const collectionRef = collection(db, "Equipo");
    const documents = await getDocs(collectionRef);
    return documents;
  } catch (error) {
    console.error("Error fetching equipo:", error);
    throw error;
  }
};

export const getServicios = async () => {
  try {
    const collectionRef = collection(db, "Servicios");
    const servicios = await getDocs(collectionRef);
    return servicios;
  } catch (error) {
    console.error("Error fetching equipo:", error);
    throw error;
  }
};

export const Servicios = async () => {
  try {
    const collectionRef = collection(db, "Servicios");
    const serviciosSnapshot = await getDocs(collectionRef);
    const serviciosData = serviciosSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return serviciosData;
  } catch (error) {
    console.error("Error obteniendo servicios:", error);
    throw error;
  }
};

export const setDataContact = async (contactData) => {
  try {
    const collectionRef = collection(db, "Citas");
    const newReservaRef = await addDoc(collectionRef, contactData);
    return true; // Indicar que la reserva fue exitosa
  } catch (error) {
    console.error(error);
    return false; // Indicar que hubo un error en la reserva
  }
};
