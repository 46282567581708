import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { getCertificados, getModulos } from "../Control/CertiFire";
import "../estilos/certificados.css";
import "../estilos/detalles.css";
import "../estilos/greek.css";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import DataScience from "../icons/datascience.png";

const Certificaciones = () => {
  const [modulos, setModulos] = useState([]);
  const [certificados, setCertificados] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  const db = getFirestore();
  const descripcionServicio = "Certificaciones para empresas y escuelas";

  const toggleAccordion = (index) => {
    console.log("Current index:", index);
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const modulosData = await getModulos();
        const certificadosData = await getCertificados();
        setCertificados(certificadosData);
        setModulos(modulosData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Certificaciones del equipo Greek | Grupo Greek</title>
        <meta name="description" content={descripcionServicio} />
      </Helmet>
      <div className="e-card playing">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div class="Titulo">
          Certificaciones del equipo Greek
          <div class="Descripcion">
            El equipo Greek trae al público en general distintas certificaciones
            individuales para que puedan aprender distintas disciplinas de la
            programación además de poder ofrecer el conocimiento y experiencia
            de los desarrolladores del equipo, poder crear un lugar donde
            empresas y escuelas puedan capacitar a su personal/alumnos con la
            garantía de obtener contenido de valor en las disciplinas
            seleccionadas.
          </div>
        </div>
      </div>

      <div class="card-compra">
        <div class="header-compra">
          <span class="title-compra">Comprar ahora</span>
          <span class="price-compra">$1542</span>
        </div>
        <p class="desc">
          Esto te dará acceso ilimitado al curso que estás seleccionado además
          de tu examen de certificación.
        </p>
        <ul class="lists">
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>Pago en línea</span>
          </li>
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>Transferencia</span>
          </li>
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>Pago seguro</span>
          </li>
        </ul>
        <button type="button" class="action">
          Pagar
        </button>
      </div>

      <div className="main-aprendizaje">
            <div className="card-aprendizaje">
              <div className="heading-aprendizaje">¿Qué aprenderás?</div>
              {certificados.map((doc, index) => (
              <div className="details">{doc.data.Descripcion}</div>
            ))}
            </div>
      </div>

      <div class="card-master">
        <div class="image"></div>
        <div class="card-info">
          <span>Mauricio Jiménez</span>
          <p>Científico de datos</p>
        </div>
      </div>

      <div className="accordion">
        {modulos.map((modulo, index) => (
          <div key={index} className="accordion-item">
            <div
              className={`accordion-title ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3 className="accordion-titulo">{modulo.data.Nombre}</h3>
              <span className="accordion-clases">Clases: 4</span>
              <span className="accordion-duracion">Duracion: 5 Horas</span>
            </div>
            <div
              className={`accordion-content ${
                activeIndex === index ? "show" : ""
              }`}
            >
              <p className="accordion-description">{modulo.data.Descripcion}</p>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Certificaciones;
