import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  limit,
  query,
  doc,
} from "firebase/firestore";
import config from "../Connect/firebase-config";

const app = initializeApp(config);
const db = getFirestore(app);

export const getModulos = async () => {
    try {
      const collectionRef = collection(db, "Modulos");
      const q = query(collectionRef, limit(4));
      const modulos = await getDocs(q);
      return modulos.docs.map( doc => ({id: doc.id, data: doc.data()}));
    } catch (error) {
      console.error("Error fetching equipo:", error);
      throw error;
    }
  };

  export const getCertificados = async () => {
    try {
      const collectionRef = collection(db, "Certificaciones");
      const q = query(collectionRef, limit(4));
      const modulos = await getDocs(q);
      return modulos.docs.map( doc => ({id: doc.id, data: doc.data()}));
    } catch (error) {
      console.error("Error fetching equipo:", error);
      throw error;
    }
  };

  export const getDataCertificado = async (certificado) => {
    try {
      const dataCertificadosRef = collection(
        doc(db, 'Certificados', certificado), "Detalles"
      );

      const querySnapshot = await getDocs(dataCertificadosRef);

      return querySnapshot.docs.map( doc => ({id: doc.id, data: doc.data()}));
    } catch (error) {
      console.error("Error fetching equipo:", error);
      throw error;
    }
  };