import React from "react";
import NavBar from "../NavBar.css";
import { Link, NavLink } from "react-router-dom";

// Definición de un componente funcional llamado FuncionalComponent
const Footer = (props) => {
  return (
    <div>
      <footer className="Footer">
        <ul className="Proximamente">
          Contacto
          <li className="ItemsProximamente">maujive@grupogreek.com</li>
          <NavLink to="/Aviso" className="ItemsProximamente">Aviso de privacidad</NavLink>
        </ul>
        <div className="DivAsesoria">
          <div className="TituloAsesoria">¿Necesitas una cotización?</div>
          <div className="Solicitar">
            <p>Solicita una totalmente gratuita!</p>
            <Link to="/Contacto">
              <button className="button">
                <p className="Solicitud">Solicitar</p>
              </button>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
